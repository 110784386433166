import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, useState } from 'react'
import ReactPlayer, { ReactPlayerProps } from 'react-player'

import { aspectRatio } from '@/theme/mixins'

import { VideoPlayIcon } from './VideoPlayIcon'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?:
    | Queries.ExternalVideoBlockFragment
    | {
        video:
          | {
              width: number | null
              height: number | null
              title: string | null
              thumbnailUrl: string | null
              url: string | null
            }
          | null
          | undefined
      }
    | null
  playerProps?: ReactPlayerProps
}

export const ExternalVideoBlock = ({
  data,
  playerProps,
  ...props
}: Props): JSX.Element => {
  const [playing, setPlaying] = useState(false)
  const video = data?.video
  const styles = {
    container: css`
      ${aspectRatio((video?.width || 0) / (video?.height || 0))};
    `,
    player: css``,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <ReactPlayer
        url={video?.url || undefined}
        light={video?.thumbnailUrl?.replace('hqdefault.jpg', 'maxresdefault.jpg') || true}
        playIcon={<VideoPlayIcon />}
        width="100%"
        height="100%"
        css={styles.player}
        onClickPreview={() => setPlaying(true)}
        playing={playing}
        {...playerProps}
      />
    </div>
  )
}

export const ExternalVideoBlockFragment = graphql`
  fragment ExternalVideoBlock on DatoCmsExternalVideoBlock {
    __typename
    id: originalId
    video {
      url
      thumbnailUrl
      title
      width
      height
    }
    title
  }
`
